import { Image, notDesktop, styled, Text } from '@obvio/app'
import { SvgSzklarniaText } from '@obvio/svg'
import { Stack } from '@obvio/ui'
import { motion, useScroll, useTransform } from 'framer-motion'
import { forwardRef, useRef } from 'react'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

type HeroProps = {
  image: ImageAsset
  text1: string
  text2: string
}

const ImageWrap = styled(Stack)`
  height: 100%;
  width: 100%;
  color: ${(theme) => theme.colors.secondary};
  text-align: left;

  transition: transform 0.3s;
  border-radius: ${(theme) => theme.radius.small};
  overflow: hidden;
`

type ImageCompProps = {
  image: ImageAsset
}

export function ImageComp({ image }: ImageCompProps): ReactElement {
  return (
    <ImageWrap>
      <Image img={image} />
    </ImageWrap>
  )
}

const ChildrenWrap = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  @media ${notDesktop} {
    span {
      font-size: 1.5rem !important;
    }
  }
`

const Left = motion(styled.div`
  z-index: 1;
  position: absolute;
  left: 5%;
  bottom: ${(theme) => theme.spacing.medium};
  @media ${notDesktop} {
    left: ${(theme) => theme.spacing.extraSmall};
    bottom: ${(theme) => theme.spacing.extraSmall};
  }
`)

const Right = motion(styled.div`
  z-index: 1;
  position: absolute;
  right: 5%;
  bottom: ${(theme) => theme.spacing.medium};
  @media ${notDesktop} {
    right: ${(theme) => theme.spacing.extraSmall};
    bottom: ${(theme) => theme.spacing.extraSmall};
  }
`)

const MainText = motion(styled.div`
  position: sticky;
  top: 10vh;

  svg {
    width: 100%;
  }
`)

type HeroImagesProps = {
  img: ImageAsset
  children: ReactElement[]
}

const ImgWrap = forwardRef<HTMLDivElement, HeroImagesProps>(
  ({ img, children }, ref) => (
    <div
      ref={ref}
      style={{ position: 'absolute', inset: 0, overflowX: 'clip' }}
    >
      <Image img={img} />
      <ChildrenWrap>{children}</ChildrenWrap>
    </div>
  ),
)

export function Hero({ image, text1, text2 }: HeroProps): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start'],
  })

  const scale = useTransform(
    scrollYProgress,
    [0.5, 1.5],
    [1, 2],
  ) as unknown as number
  const opacity = useTransform(
    scrollYProgress,
    [0.5, 0.7],
    [1, 0],
  ) as unknown as number

  return (
    <ImgWrap img={image} ref={ref}>
      <MainText style={{ scale }}>
        <SvgSzklarniaText width="65%" />
      </MainText>
      <Left style={{ opacity }}>
        <Text tag="span" as="h2">
          {text1}
        </Text>
      </Left>
      <Right style={{ opacity }}>
        <Text tag="span" as="h2">
          {text2}
        </Text>
      </Right>
    </ImgWrap>
  )
}
